import {
  Button,
  Text,
  AudiPlatformProvider,
  audiDarkTheme,
  audiLightTheme,
} from '@audi/audi-ui-react';
import {
  Logo,
  MaterialDisclaimer,
  ResponsiveMedia,
  withScrollToElement,
} from '@volkswagen-onehub/aoa-shared-editorial-components';
import { shape, string, bool, number, object, oneOf, arrayOf } from 'prop-types';
import React from 'react';
import uuid from 'react-uuid';
import { renderTextWithFootnotesReferences } from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';

import {
  Container,
  InnerWrapper,
  CTAsContainer,
  ContentContainer,
  Heading,
  SubHeading,
  TextContainer,
  MediaContainer,
  LogoContainer,
  Icon,
  StyledAnchor,
  DisclaimerWrapper,
} from './styles';

const ButtonWithScrollToElement = withScrollToElement(Button);

const Anchor = ({ href, newWindow, children, ...rest }) => (
  <StyledAnchor href={href} target={newWindow ? '_blank' : '_self'} {...rest}>
    {children}
  </StyledAnchor>
);

Anchor.displayName = 'Anchor';

const AnchorWithScroll = withScrollToElement(Anchor);

const stopPropagation = (e) => {
  e.stopPropagation();
};

export const ContentTile = ({
  CTA,
  heading,
  horizontalAlignment,
  horizontalAlignmentSmall,
  icon,
  logo,
  materialDisclaimer,
  media,
  mobileBreakpointSizes,
  size,
  subHeading,
  theme,
  verticalAlignment,
  verticalAlignmentSmall,
}) => (
  <AnchorWithScroll
    href={CTA.href}
    newWindow={media.newWindow}
    data-test-id="content-tile-image-anchor"
  >
    <AudiPlatformProvider theme={theme === 'light' ? audiLightTheme : audiDarkTheme}>
      <Container
        data-test-id="content-tile-container"
        size={size}
        mobileBreakpointSizes={mobileBreakpointSizes}
        zoomEnabled={media.zoomEnabled}
      >
        <InnerWrapper
          data-test-id="content-tile-inner-wrapper"
          verticalAlignment={verticalAlignment}
          verticalAlignmentSmall={verticalAlignmentSmall}
          horizontalAlignment={horizontalAlignment}
          horizontalAlignmentSmall={horizontalAlignmentSmall}
        >
          <MediaContainer>
            <ResponsiveMedia {...media} cover />
            <MaterialDisclaimer materialDisclaimer={materialDisclaimer} />
          </MediaContainer>
          <ContentContainer>
            <TextContainer>
              <LogoContainer
                horizontalAlignment={horizontalAlignment}
                horizontalAlignmentSmall={horizontalAlignmentSmall}
              >
                {logo.src && !icon && <Logo src={logo.src} alt={logo.alt} heights={logo.heights} />}
                {!logo.src && icon && (
                  <Icon
                    horizontalAlignment={horizontalAlignment}
                    horizontalAlignmentSmall={horizontalAlignmentSmall}
                    dangerouslySetInnerHTML={{ __html: icon }}
                  />
                )}
              </LogoContainer>
              {heading.text && (
                <Heading
                  horizontalAlignment={horizontalAlignment}
                  horizontalAlignmentSmall={horizontalAlignmentSmall}
                >
                  <Text variant={heading.variant} as={heading.htmlTag}>
                    <DisclaimerWrapper onClick={stopPropagation}>
                      {renderTextWithFootnotesReferences(heading.text)}
                    </DisclaimerWrapper>
                  </Text>
                </Heading>
              )}
              {subHeading.text && !logo.src && !icon && (
                <SubHeading
                  horizontalAlignment={horizontalAlignment}
                  horizontalAlignmentSmall={horizontalAlignmentSmall}
                >
                  <Text variant={subHeading.variant} as={subHeading.htmlTag}>
                    <DisclaimerWrapper onClick={stopPropagation}>
                      {renderTextWithFootnotesReferences(subHeading.text)}
                    </DisclaimerWrapper>
                  </Text>
                </SubHeading>
              )}
            </TextContainer>
            {CTA.text && (
              <CTAsContainer
                horizontalAlignment={horizontalAlignment}
                horizontalAlignmentSmall={horizontalAlignmentSmall}
              >
                <ButtonWithScrollToElement
                  variant="text"
                  key={uuid()}
                  href={CTA.href}
                  newWindow={CTA.newWindow}
                  data-test-id="content-tile-cta"
                >
                  {CTA.text}
                </ButtonWithScrollToElement>
              </CTAsContainer>
            )}
          </ContentContainer>
        </InnerWrapper>
      </Container>
    </AudiPlatformProvider>
  </AnchorWithScroll>
);

ContentTile.displayName = 'ContentTile';

const materialDisclaimerShape = shape({
  disclaimer: string,
  theme: oneOf(['light', 'dark']),
  position: oneOf(['bottomLeft', 'bottomRight', 'topLeft', 'topRight']),
});

const srcShape = shape({
  src: string.isRequired,
  poster: string.isRequired,
});

ContentTile.propTypes = {
  size: number, // Value to be applied to the height of the tile
  mobileBreakpointSizes: object, // Values to be applied to the height of the tile at 375 & 768 breakpoints
  theme: oneOf(['light', 'dark']).isRequired,
  icon: string,
  media: shape({
    newWindow: bool,
    fadeEnabled: bool,
    fade: shape({
      color: string, // hex color, if none present will use theme color
      opacity: string, // 0 -1
      height: string, // percentage 0-100
      direction: oneOf(['top', 'bottom', 'left', 'right']),
    }),
    imageEnabled: bool.isRequired,
    videoEnabled: bool.isRequired,
    image: shape({
      alt: string,
      srcSet: arrayOf(
        shape({
          bp: oneOf(['xs', 's', 'm', 'l', 'xl', 'xxl']),
          src: string,
        })
      ),
    }),
    video: shape({
      autoplay: bool,
      loop: bool,
      srcs: shape({
        xs: srcShape,
        s: srcShape,
        m: srcShape,
        l: srcShape,
        xl: srcShape,
        xxl: srcShape,
      }),
    }),
  }).isRequired,
  logo: shape({
    alt: string,
    src: string,
  }),
  heading: shape({
    text: string,
    variant: oneOf(['order1', 'order2', 'order3', 'order4', 'display1', 'display2']),
    htmlTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    embolden: bool,
  }),
  subHeading: shape({
    text: string,
    variant: oneOf(['order1', 'order2', 'order3', 'order4', 'display1', 'display2']),
    htmlTag: oneOf(['h2', 'h3', 'h4', 'h5', 'h6', 'p']),
    embolden: bool,
  }),
  CTA: shape({
    href: string,
    variant: oneOf(['primary', 'secondary', 'text']),
    text: string,
    newWindow: bool,
  }),
  horizontalAlignment: oneOf(['left', 'center', 'right']),
  horizontalAlignmentSmall: oneOf(['left', 'center', 'right']),
  verticalAlignment: oneOf(['top', 'center', 'bottom']),
  verticalAlignmentSmall: oneOf(['top', 'center', 'bottom']),
  materialDisclaimer: shape({
    xs: materialDisclaimerShape,
    s: materialDisclaimerShape,
    m: materialDisclaimerShape,
    l: materialDisclaimerShape,
    xl: materialDisclaimerShape,
    xxl: materialDisclaimerShape,
  }).isRequired,
};
