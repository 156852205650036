import { withResponsiveDetails } from '@volkswagen-onehub/aoa-shared-editorial-components';
import { arrayOf, shape, oneOf } from 'prop-types';
import React from 'react';
import uuid from 'react-uuid';

import { CT_LAYOUT, CT_SIZE } from './constants';
import { ContentTileContainer } from '../ContentTile/ContentTileContainer';
import { Container, SplitColumnContainer } from './styles';
import { props as contentTileDefaults } from './testData';
import { setTileWidths } from './utils';
import { withFocusReset } from './withFocusReset';

// exported for testing purposes
export const _ContentTiles = ({
  tiles,
  layout: { name: layoutName, size: layoutSize },
  currentBreakpoint,
}) => (
  <Container layout={layoutName}>
    {layoutName === CT_LAYOUT.IRREGULAR_2X1_SPLIT_COLUMN_RIGHT ? (
      <>
        <ContentTileContainer
          index={0}
          tileData={tiles[0]}
          setSize={false}
          setTileHeightsDirectly={false}
          leadCardIndex={0}
          layoutName={layoutName}
          layoutSize={layoutSize}
          width={setTileWidths(0, layoutName)}
        />
        <SplitColumnContainer>
          {tiles.map((tile, index) => {
            if (index === 0) return false;
            return (
              <ContentTileContainer
                key={uuid()}
                index={index}
                tileData={tile}
                setSize
                setTileHeightsDirectly
                leadCardIndex={0}
                layoutName={layoutName}
                layoutSize={layoutSize}
                width={setTileWidths(index, layoutName)}
              />
            );
          })}
        </SplitColumnContainer>
      </>
    ) : layoutName === CT_LAYOUT.IRREGULAR_2X1_SPLIT_COLUMN_LEFT ? (
      <>
        {(currentBreakpoint === 'xs' || currentBreakpoint === 's' || currentBreakpoint === 'm') && (
          <ContentTileContainer
            index={2}
            tileData={tiles[2]}
            setSize={false}
            setTileHeightsDirectly={false}
            leadCardIndex={2}
            layoutName={layoutName}
            layoutSize={layoutSize}
            width={setTileWidths(2, layoutName)}
          />
        )}
        <SplitColumnContainer>
          {tiles.map((tile, index) => {
            if (index === 2) return false;

            return (
              <ContentTileContainer
                index={index}
                tileData={tile}
                setSize
                setTileHeightsDirectly
                leadCardIndex={2}
                layoutName={layoutName}
                layoutSize={layoutSize}
                width={setTileWidths(index, layoutName)}
                key={uuid()}
              />
            );
          })}
        </SplitColumnContainer>
        {(currentBreakpoint === 'l' ||
          currentBreakpoint === 'xl' ||
          currentBreakpoint === 'xxl') && (
          <ContentTileContainer
            index={2}
            tileData={tiles[2]}
            setSize={false}
            setTileHeightsDirectly={false}
            leadCardIndex={2}
            layoutName={layoutName}
            layoutSize={layoutSize}
            width={setTileWidths(2, layoutName)}
            key={uuid()}
          />
        )}
      </>
    ) : (
      tiles.map((tile, index) => (
        <ContentTileContainer
          index={index}
          tileData={tile}
          setSize
          setTileHeightsDirectly={false}
          leadCardIndex={0}
          layoutName={layoutName}
          layoutSize={layoutSize}
          width={setTileWidths(index, layoutName)}
          key={uuid()}
        />
      ))
    )}
  </Container>
);

_ContentTiles.displayName = 'ContentTiles';

_ContentTiles.propTypes = {
  tiles: arrayOf(shape(ContentTileContainer.propTypes.tileData)),
  layout: shape({
    name: oneOf([...Object.values(CT_LAYOUT)]),
    size: oneOf([...Object.values(CT_SIZE)]),
  }),
};

_ContentTiles.defaultProps = contentTileDefaults;

export const ContentTiles = withFocusReset(withResponsiveDetails(_ContentTiles));
