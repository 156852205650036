/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React, { useContext } from 'react';
import { App, ProviderProps as AppProps } from '@volkswagen-onehub/oneaudi-os-react';
import { ContentTiles } from './components/ContentTiles';
import { ContentContext } from './context';

const FeatureApp: React.FC<AppProps> = ({ ...inherited }: AppProps) => {
  const app = {
    // eslint-disable-next-line no-underscore-dangle
    name: __FEATURE_APP_NAME__,
    // eslint-disable-next-line no-underscore-dangle
    version: __FEATURE_APP_VERSION__,
  };

  const appConfig: { [k: string]: any } = { appName: __FEATURE_APP_NAME__ };

  const context = useContext(ContentContext);

  return (
    <App config={appConfig} app={app} {...inherited}>
      <ContentTiles {...context.content} />
    </App>
  );
};

export default FeatureApp;
