/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState } from 'react';
import { ContentServiceV1, ContentChangeListener } from '@volkswagen-onehub/audi-content-service';

import { ContentContext } from './ContentContext';

export const ContentContextProvider: React.FC<{ contentService: ContentServiceV1 }> = ({
  contentService,
  children,
}) => {
  const [content, setContent] = useState(contentService.getContent());
  contentService.onContentChange(setContent as ContentChangeListener);

  return <ContentContext.Provider value={{ content }}>{children}</ContentContext.Provider>;
};

ContentContextProvider.displayName = 'ContentContextProvider';
