/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { ContentServiceV1 } from '@volkswagen-onehub/audi-content-service';
import {
  AudiFootnoteReferenceServiceInterfaceV3,
  AudiFootnoteRefernceServiceScopeManagerInterfaceV3,
} from '@volkswagen-onehub/audi-footnote-reference-service';
import { FootnoteContextProvider } from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';

import App from './FeatureApp';
import { ContentContextProvider } from './context';

interface Dependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies> = {
  dependencies: {
    featureServices: {
      'audi-footnote-reference-service': '^3.0.0',
    },
    externals: {
      react: '^16.13.1 || ^17.0.2',
      'react-dom': '^16.13.1 || ^17.0.2',
      'styled-components': '*',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-content-service': '^1.0.0',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<Dependencies, void>) => {
    const logger = featureServices['s2:logger'];
    const contentService = featureServices['audi-content-service'];
    logger?.info('Feature App created.');
    const referenceServiceScopeManager: AudiFootnoteRefernceServiceScopeManagerInterfaceV3 =
      featureServices['audi-footnote-reference-service'];
    const footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3 =
      referenceServiceScopeManager.getDefaultScopeRefService();

    return {
      render: () => {
        return (
          <ContentContextProvider contentService={contentService}>
            <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
              <App />
            </FootnoteContextProvider>
          </ContentContextProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
