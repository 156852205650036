import { CT_LAYOUT } from './constants';
import { TileSizes } from './tileSizes';

export const setTileHeights = (ratio) => ratio * 100;

export const handleSetTileHeights = (index, layoutName, layoutSize) => {
  if (layoutName === CT_LAYOUT.IRREGULAR_2X1_WIDE) {
    // eslint-disable-next-line
    return index === 0 ? setTileHeights(TileSizes[layoutName][layoutSize]) : null;
  }
  if (layoutName === CT_LAYOUT.IRREGULAR_1X2_SPLIT_ROW) {
    // eslint-disable-next-line
    return index === 0
      ? setTileHeights(TileSizes[layoutName].sizeOne[layoutSize])
      : setTileHeights(TileSizes[layoutName].sizeTwo[layoutSize]);
  }
  return setTileHeights(TileSizes[layoutName][layoutSize]);
};

export const setMobileTileHeights = (
  index,
  leadCardIndex,
  breakpointLayout,
  layoutSize,
  layoutName
) => {
  let tileHeight;

  if (!layoutName.includes('Irregular')) {
    if (layoutName === CT_LAYOUT.BASIC_2X2) {
      // eslint-disable-next-line
      return TileSizes[breakpointLayout][layoutSize === 'tall' ? 'standard' : 'short'] * 100;
    }
    return TileSizes[breakpointLayout][layoutSize] * 100;
  }

  if (layoutSize === 'short' && index === leadCardIndex) {
    tileHeight = TileSizes[breakpointLayout].standard * 100;
  } else if (index === leadCardIndex) {
    tileHeight = TileSizes[breakpointLayout].tall * 100;
  }

  if (layoutSize === 'short' && index !== leadCardIndex) {
    tileHeight = TileSizes[breakpointLayout].short * 100;
  } else if (index !== leadCardIndex) {
    tileHeight = TileSizes[breakpointLayout].standard * 100;
  }

  return tileHeight;
};

/* eslint-disable default-case */
export const setTileWidths = (index, layoutName) => {
  switch (layoutName) {
    case CT_LAYOUT.BASIC_2X1:
    case CT_LAYOUT.BASIC_2X2:
      return '50%';
    case CT_LAYOUT.BASIC_3X1:
      return '33.33%';
    case CT_LAYOUT.IRREGULAR_2X1_WIDE:
      return index === 0 ? '66%' : '34%';
    case CT_LAYOUT.IRREGULAR_2X1_SPLIT_COLUMN_RIGHT:
      return index === 0 ? '50%' : '100%';
    case CT_LAYOUT.IRREGULAR_2X1_SPLIT_COLUMN_LEFT:
      return index === 2 ? '50%' : '100%';
    case CT_LAYOUT.IRREGULAR_1X2_SPLIT_ROW:
      return index === 0 ? '100%' : '50%';
  }
};
