import styled from 'styled-components';

const breakpoints = {
  l: '1024',
};

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 36px 9px;

  @media (min-width: ${breakpoints.l}px) {
    padding: 54px;
  }
`;

// The .event-shield & .edit styles are to make sure that the authoring wrapping classes
// do not stop the tiles from having any height
export const StyledContentTileContainer = styled.div`
  box-sizing: border-box;
  padding: 6px;
  width: 100%;

  @media (min-width: ${breakpoints.l}px) {
    width: ${({ width }) => `${width};`};
  }

  .event-shield {
    height: 100%;
  }

  .edit > div {
    height: 100%;
  }
`;

export const SplitColumnContainer = styled.div`
  width: 100%;
  @media (min-width: ${breakpoints.l}px) {
    width: 50%;
  }
`;
