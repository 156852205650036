import { bool, string, number, shape } from 'prop-types';
import React from 'react';

import { StyledContentTileContainer } from '../ContentTiles/styles';
import { TileSizes } from '../ContentTiles/tileSizes';
import { handleSetTileHeights, setTileHeights, setMobileTileHeights } from '../ContentTiles/utils';

import { ContentTile } from '.';

export const ContentTileContainer = ({
  index,
  tileData,
  setSize,
  setTileHeightsDirectly,
  leadCardIndex,
  layoutName,
  layoutSize,
  width,
}) => (
  <StyledContentTileContainer width={width} layout={layoutName}>
    <ContentTile
      // eslint-disable-next-line
      key={index}
      {...tileData}
      size={
        setSize && !setTileHeightsDirectly
          ? handleSetTileHeights(index, layoutName, layoutSize)
          : setSize && setTileHeightsDirectly
          ? setTileHeights(TileSizes[layoutName].sizeTwo[layoutSize])
          : null
      }
      mobileBreakpointSizes={{
        s: setMobileTileHeights(index, leadCardIndex, '375 breakpoint', layoutSize, layoutName),
        m: setMobileTileHeights(index, leadCardIndex, '768 breakpoint', layoutSize, layoutName),
      }}
    />
  </StyledContentTileContainer>
);

ContentTileContainer.displayName = 'ContentTileContainer';

ContentTileContainer.propTypes = {
  index: number.isRequired,
  tileData: shape(ContentTile.propTypes),
  setSize: bool.isRequired,
  setTileHeightsDirectly: bool.isRequired,
  leadCardIndex: number.isRequired,
  layoutName: string.isRequired,
  layoutSize: string.isRequired,
  width: string,
};
